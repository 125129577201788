import React, { useState } from "react";
import BaseLayout from "../../Layout/BaseLayout";
import AllProducts from "./AllProduct";
import { useFormik } from "formik";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { collection, addDoc, serverTimestamp } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { updateDoc } from "firebase/firestore";

import { useStorage } from "reactfire";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import { BsPlusLg } from "react-icons/bs";
import { BiTrash } from "react-icons/bi";

const fileTypes = ["JPG", "PNG", "GIF"];

function AddProduct() {
  const firestore = useFirestore();
  const storage = useStorage();

  const [colorList, setColorList] = useState(["#000000"]);
  const [selectedColor, setSelectedColor] = useState("#000000");

  const [Loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);

  //Togle add Product
  const [productToggle, setproductToggle] = useState(false);

  //Colors of the product
  // const colors = ["white", "gray", "red", "green", "blue"];
  const categorias = [
    {
      category: "Hoddies",
      value: "Hoddies",
    },
    {
      category: "T-Shirts",
      value: "T-Shirts",
    },
    {
      category: "Pants",
      value: "Pants",
    },
  ];

  const genders = [
    {
      type: "Male",
      value: "male",
    },
    {
      type: "Female",
      value: "female",
    },
    {
      type: "Unisex",
      value: "unisex",
    },
  ];

  const Positions = [
    {
      position: "Center",
      value: "center",
    },
    {
      position: "Left",
      value: "left",
    },
    {
      position: "Right",
      value: "right",
    },
  ];

  const addNewColor = () => {

    const FilteredList = colorList.filter((cl) => cl === selectedColor)


    console.log(FilteredList)

    if(FilteredList.length > 0){
      alert("Color Repetido")
    } else {
      setColorList([...colorList, selectedColor]);
    }


    
    console.log(colorList);


  };

  const RemoveColor = (i) => {
    const filteredColorList = colorList.filter((cl, index) => index !== i);
    setColorList(filteredColorList);
  };

  const ChangeColor = (color) => {
    // console.log(color)
    setSelectedColor(color.target.value);
    // console.log(selectedColor)f
  };

  //////form methods

  const addProduct = async (values) => {
    //objeto producto
    const producto = {
      productCode: values.Code,
      img: "https://firebasestorage.googleapis.com/v0/b/printwarpstudioapp-1870e.appspot.com/o/Productos%2FZ96jCaYRxvWmTH6UEggm%2F2022-12-07%2013%3A31%3A44.120648.jpg?alt=media&token=5b2ff7c4-b7d1-4cf7-9e38-983565e8778f",
      productName: values.Product,
      price: values.Price,
      amount: values.Stock,
      category: values.Category,
      gender: values.Gender,
      position: values.Position,
      colors: colorList,
      productSize: values.Size,
      active: true,
      createdAt: serverTimestamp(),
    };

    //  console.log(producto)

    setLoading(true);
    try {
      //creacion de objeto en firestore
      const respDocRef = await addDoc(
        collection(firestore, "Products"),
        producto
      );
      // console.log("Document written with ID: ", respDocRef.id);

      if (respDocRef.id) {
        //almacenado de imagen en firestorage

        if (file) {
          const newRef = ref(
            storage,
            `Productos/${respDocRef.id}/${file.name}`
          );

          await uploadBytes(newRef, file);

          const link = await getDownloadURL(newRef);

          // console.log(link);

          await updateDoc(respDocRef, {
            img: link,
          });

          
        }
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Product created and picture uploaded successfully",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.reload(false);

        setLoading(false);
      }
    } catch (error) {
      //   const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error.message);
      // console.log(errorMessage)
      setLoading(false);
      alert(errorMessage);
    }
  };

  //VALIDACIONES PARA FORM
  const validate = (values) => {
    const errors = {};
    // console.log(values)

    if (!values.Product) {
      errors.Product = "Product Name Required";
    }
    if (!values.Price) {
      errors.Price = "Product Price Required";
    } else if (values.Price < 0 || values.Price === 0) {
      errors.Price = "Value must be greater than 0";
    }
    if (!values.Stock) {
      errors.Stock = "Product Stock Required";
    } else if (values.Stock < 0 || values.Stock === 0) {
      errors.Stock = "Value must be greater than 0";
    }
    if (!values.Category) {
      errors.Category = "Category Required";
    }
    if (!values.Gender) {
      errors.property = "Gender Required";
    }
    if (!values.Position) {
      errors.Position = "Position Required";
    }
    if (!values.Size) {
      errors.Size = "Product Size Required";
    }

    return errors;
  };
  //FORMIK HOOK
  const formik = useFormik({
    initialValues: {
      Code: "",
      Product: "",
      Price: 0,
      Stock: 0,
      Category: "T-Shirts",
      Gender: "male",
      Position: "center",
      Size: "",
    },
    validate,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      console.log(values);
      // alert(JSON.stringify(values, null, 2));

      await addProduct(values);
      actions.setSubmitting(false);
      actions.resetForm({
        values: {
          Code: "",
          Product: "",
          Price: 0,
          Stock: 0,
          Category: "T-Shirts",
          Gender: "unisex",
          Position: "center",
          Size: "",
        },
      });
    },
  });

  const handleChange = (file) => {
    setFile(file);
  };

  if (Loading) {
    return <LoadingSpinner />;
  }

  return (
    <BaseLayout>
      <div>
        <div className="flex items-center self-center justify-between">
          <h1 className="text-center text-[30px] font-bold mt-4">
            All Products
          </h1>
          <button
            type="button"
            className="flex px-4 py-2 mx-5 text-white transition-all transform bg-[#e04f1e] rounded-md shadow-lg outline-none focus:ring-4"
            onClick={() => setproductToggle(!productToggle)}
          >
            <span className="ml-2"> Add Products</span>
          </button>
        </div>
        {productToggle ? (
          <div className="px-4 pt-5 pb-4 transition-all ease-out delay-150 bg-white sm:p-6 sm:pb-4">
            <div className="flex flex-col mx-2 md:flex-row">
              <div className="md:w-2/4 flex flex-col text-center self-center mx-4 my-2 border-[3px] rounded-md border-gray">
                <FileUploader
                  handleChange={handleChange}
                  name="file"
                  types={fileTypes}
                />
                <img
                  src={
                    file
                      ? URL.createObjectURL(file)
                      : "https://firebasestorage.googleapis.com/v0/b/searchapp-25415.appspot.com/o/ef3-placeholder-image.jpg?alt=media&token=20f95a9b-c891-477d-88c5-cb4f12d5a3c0"
                  }
                  alt=""
                  className="w-full h-[250px] mx-auto object-contain p-2 m-4"
                />
              </div>
              <div className="flex flex-col self-center my-4 md:w-4/5 md:mx-2">
                <form onSubmit={formik.handleSubmit}>
                  <div className="grid gap-3 mb-6 md:grid-cols-2">
                    <div className="mt-4">
                      <label htmlFor="Code" className="block text-gray-700">
                        Product Code
                      </label>
                      <input
                        id="Code"
                        name="Code"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Code}
                      />
                      {formik.errors.Code ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Code}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label htmlFor="Product" className="block text-gray-700">
                        Product
                      </label>
                      <input
                        id="Product"
                        name="Product"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Product}
                      />
                      {formik.errors.Product ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Product}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label htmlFor="Price" className="block text-gray-700">
                        Price
                      </label>
                      <input
                        id="Price"
                        name="Price"
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Price}
                      />
                      {formik.errors.Price ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Price}
                        </div>
                      ) : null}
                    </div>
                    <div className="mt-4">
                      <label htmlFor="email" className="block text-gray-700">
                        Stock
                      </label>
                      <input
                        id="Stock"
                        name="Stock"
                        type="number"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Stock}
                      />
                      {formik.errors.Stock ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Stock}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-4">
                      <label htmlFor="Category" className="block text-gray-700">
                        Category
                      </label>
                      <select
                        id="Category"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Category}
                      >
                        {categorias.map((value, i) => {
                          return (
                            <option key={i} value={value.value}>
                              {value.category}
                            </option>
                          );
                        })}
                      </select>
                      {formik.errors.Category ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Category}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-4">
                      <label
                        htmlFor="Gender"
                        className="block text-gray-700"
                      >
                        Gender
                      </label>
                      <select
                        id="Gender"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Gender}
                      >
                        {genders.map((value, i) => {
                          return (
                            <option key={i} value={value.value}>
                              {value.type}
                            </option>
                          );
                        })}
                      </select>
                      {formik.errors.Gender ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Gender}
                        </div>
                      ) : null}
                    </div>

                    <div className="mt-4">
                      <label htmlFor="Position" className="block text-gray-700">
                        Position
                      </label>
                      <select
                        id="Position"
                        type="text"
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={formik.handleChange}
                        value={formik.values.Position}
                      >
                        {Positions.map((value, i) => {
                          return (
                            <option key={i} value={value.value}>
                              {value.position}
                            </option>
                          );
                        })}
                      </select>
                      {formik.errors.Position ? (
                        <div className="font-bold text-red-600">
                          {formik.errors.Position}
                        </div>
                      ) : null}
                    </div>

                    <div className="flex flex-wrap items-center">
                      <span className="w-full">Color</span>
                      <div>
                        <div className="flex items-center self-center mb-2">
                          <input
                            type="color"
                            id="color"
                            onChange={ChangeColor}
                            value={selectedColor}
                            className="w-[200px] h-[35px]"
                          />
                          <span
                            id="new"
                            onClick={addNewColor}
                            className="bg-[#e04f1e] text-white p-2 rounded-[2px]"
                          >
                            <BsPlusLg className="text-[12px]" />
                          </span>
                        </div>
                        <div id="wrapper">
                          <ul id="listColor" className="flex">
                            {colorList.map((color, i) => {
                              return (
                                <li key={i}  style={{ 
                                  //Poner por el color negro
                                  backgroundColor: color,
                                 }} className="mx-[2px] rounded-[4px]">
                                  <div className="buttons">
                                    <span
                                      className="cursor-pointer remove"
                                      onClick={() => RemoveColor(i)}
                                    >
                                      <BiTrash className="text-[30px] text-white p-[3px]"/>
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                      {/* Color Picker */}
                      <div className="flex flex-wrap"></div>
                    </div>
                    <div className="flex items-center self-center">
                      <span className="mr-3">Size</span>
                      <div className="flex flex-col">
                        <input
                          id="Size"
                          name="Size"
                          type="text"
                          className="bg-gray-50 mx-[2px] border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block p-1 focus:ring-blue-500 w-[100px] text-center my-1"
                          onChange={formik.handleChange}
                          value={formik.values.Size}
                        />
                        {formik.errors.Size ? (
                          <div className="font-bold text-red-600">
                            {formik.errors.Size}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <button
                    type="submit"
                    className="text-white bg-[#e04f1e] hover:bg-[#e04f1e] focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center "
                  >
                    Save
                  </button>
                </form>
              </div>
            </div>
          </div>
        ) : null}
        <AllProducts />
      </div>
    </BaseLayout>
  );
}

export default AddProduct;
