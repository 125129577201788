import React, { useState } from "react";
import { FiDelete } from "react-icons/fi";
import { MdCancel } from "react-icons/md";
import { GiCancel } from "react-icons/gi"
import { doc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { updateDoc } from "firebase/firestore";
import Swal from "sweetalert2";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";



function DeleteClient({ eventClose, client }) {
  console.log(client)
  const firestore = useFirestore();
  const [Loading, setLoading] = useState(false);
  const eliminarClient = async () => {

    setLoading(true);
    try {
      //edicion de objeto en firestore

      const producRef = doc(firestore, "Users", client.uid);
      await updateDoc(
        producRef,
        {
          active: !client.active
        }
      );



      Swal.fire({
        position: "center",
        icon: "success",
        title: "Client disabled successfully",
        showConfirmButton: false,
        timer: 1500,
      });

      setLoading(false);
      //se cierra la modal

      const btn = document.getElementById("closeButton")
      btn.click()



    } catch (error) {
      //   const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error.message);
      // console.log(errorMessage)
      setLoading(false);

      Swal.fire({
        position: "center",
        icon: "error",
        title: `${errorMessage}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }

  }
  return (
    <div className="fixed top-0 left-0 z-50 w-full">
      <div className="flex items-center justify-center px-4 pt-4 pb-20 text-center min-height-100vh sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-80" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle md:w-[30vw] w-[95%]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >

          <div className="flex justify-end w-full">
            <button
              id="closeButton"
              className="relative bg-black text-white px-2 text-[18px] rounded-full text-end"
              onClick={eventClose}
            >
              x
            </button>
          </div>
          {

            Loading === true ? <LoadingSpinner /> : <>

              <div className="bg-white">
                <div className="flex flex-col items-center justify-center text-center">
                  <GiCancel className="bg-red-600 text-white text-[35px] rounded-full" />
                  <h1 className="py-4 text-2xl font-bold text-center">Are you sure?</h1>
                </div>
                <div className="flex justify-center pb-6">
                  <button
                    type="button"
                    className="flex px-4 py-3 mx-5 text-white transition-transform transform bg-blue-600 rounded-md shadow-lg outline-none focus:ring-4"
                    onClick={eliminarClient}
                  >
                    <FiDelete className="w-4 h-6" />
                    <span className="ml-2">Delete</span>
                  </button>
                  <button
                    type="button"
                    className="flex px-4 py-3 mx-5 text-white transition-transform transform bg-blue-600 rounded-md shadow-lg outline-none focus:ring-4"
                    onClick={eventClose}
                  >
                    <MdCancel className="w-4 h-6" />
                    <span className="ml-2">Cancel</span>
                  </button>
                </div>
              </div></>
          }
        </div>
      </div>
    </div>
  );
}

export default DeleteClient;
