import React from "react";
import Sidebar from "../components/Sidebar/Sidebar";

function BaseLayout(props) {
  return (
    <>
      <div className="md:flex">
        
      <Sidebar />

      <main className='md:w-4/5 mx-auto md:h-[100vh] md:py-10 md:px-4 overflow-auto scroll_style'>{props.children}</main>
      </div>
    </>
  );
}

export default BaseLayout;
