import React from "react";
import { NavLink } from "react-router-dom";
import MainMenu from "./Menu";

function MovilMenu() {
  return (
    <>
      <nav className="w-full fixed bottom-0 z-50 px-3 bg-black md:hidden block">
        <ul className="flex list-none justify-between">
          {MainMenu.map((item, index) => {
            return (
              <li className="w-[13%]" key={index}>
                <NavLink to={item.link} className="link">
                  <span className="link text-center block text-[0.75em] no-underline py-[0.5em] px-0 text-white">
                    <span className="w-[1.5em] h-[1.5em] block mx-auto m-[5px]">
                      {React.createElement(item?.icon, { size: "20" })}
                    </span>
                    {item.name}
                  </span>
                </NavLink>
              </li>
            );
          })}
        </ul>
      </nav>
    </>
  );
}

export default MovilMenu;
