import React, {useState} from "react";
import DataTable from "react-data-table-component";
import BaseLayout from "../../Layout/BaseLayout";


//  Firebase
import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { collection, orderBy, query,where } from "firebase/firestore";
import DeleteClient from "../Modal/DeleteClient";
import { BsFillTrashFill } from 'react-icons/bs';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';


function Users() {

  const firestore = useFirestore();
  const usersCollection = collection(firestore, "Users");
  const [isAscending] = useState(false);
  const [active, setActive] = useState(true);
  //Seleted Product
  const [selecteClient, setSelectedClient] = useState({})
 //Modal Delete Product
  const [modalDelete, setModalDelete] = useState(false);


  const usersQuery = query(
    usersCollection,
    orderBy("createdAt", isAscending ? "asc" : "desc"),
      where("active","==",active),
  );
  const { status, data } = useFirestoreCollectionData(usersQuery, {
    idField: "id",
  });


  //Start Data Table Columns
  const columns = [
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Password",
      selector: row => row.password,
      sortable: true,
    },
    {
      name: "Address",
      selector: row => row.address,
      sortable: true,
    },
    {
      name: "Phone",
      selector: row => row.phone,
      sortable: true,
    },
    {
      name: "createDate",
      selector: row => row.createdAt,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      sortable: true,
    },

  ];



  const EstadosUser = [
  
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  if (status === "loading") {
    return <LoadingSpinner />;
  }
  return (
    <BaseLayout>
      {/* <AddUser />   */}
      <h2 className="text-[22px] font-bold text-center my-2">User List</h2>
      <div className="mt-4">
                      <label htmlFor="Position" className="block text-gray-700">
                        Products Filter
                      </label>
                      <select
                       
                     
                        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                        onChange={(e)=> {
                        const val = e.target.value.toLowerCase()==='true'? true : false;
                          setActive(val)}}
                        value={active}
                      >
                        {EstadosUser.map((value, i) => {
                          return (
                            <option key={i} value={value.value}>
                              {value.label}
                            </option>
                          );
                        })}
                      </select>
                    
                    </div>
      <div className="text-black">
        <DataTable
          //Customize Table
          columns={columns}
          //Customize Data
          //dejar fixed para que no se mueva la tabla
          data={data?.map((list) => ({
            id: list.uid,
            name: list.clientName,
            email: list.email,
            password: list.password,
            address: list.clientAddress,
            phone: list.clientPhone,
            createdAt: new Date(list.createdAt.seconds * 1000).toDateString(),
            status: list.active === true? "Active" : "Disabled",
            actions: (
              <div className="flex">
                
                <button
                  className="flex justify-center tex items-center text-white bg-red-600 py-[7px] p-2 text-[18px] font-bold rounded-lg"
                  onClick={() => {
                    setSelectedClient(list)
                    setModalDelete(!modalDelete)
                  }}
                >
                  <BsFillTrashFill />
                </button>
              </div>
            ),
          }))}
          pagination
          isFilterable={true}
        />
      </div>
      {modalDelete ? (
        <DeleteClient
          eventClose={() => setModalDelete(false)}
          client={selecteClient}

        />
      ) : null}
    </BaseLayout>
  );
}

export default Users;
