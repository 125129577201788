import React from "react";

const LoadingSpinner = ({ label = "Cargando" }) => {
  return (
    <div className="flex flex-col items-center justify-center h-screen mb-4">
      <div className="flex flex-col items-center justify-center">
        <div className="lds-ripple">
          <div></div>
          <div></div>
        </div>
        {label}...
      </div>
    </div>
  );
};

export default LoadingSpinner;
