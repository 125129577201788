import React, { useState } from "react";
import { useFormik } from "formik";
import { BiSave, BiTrash } from "react-icons/bi";

import { doc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import { ref, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { updateDoc } from "firebase/firestore";

import { useStorage } from "reactfire";
import { FileUploader } from "react-drag-drop-files";
import Swal from "sweetalert2";
import { BsPlusLg } from "react-icons/bs";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";

const fileTypes = ["JPG", "PNG", "GIF"];

function EditProduct({ eventClose, product }) {
  const firestore = useFirestore();
  const storage = useStorage();

  const [Loading, setLoading] = useState(false);

  const [file, setFile] = useState(null);

  const [colorList, setColorList] = useState(product.colors);
  const [selectedColor, setSelectedColor] = useState("#000000");

  console.log(product);

  const categorias = [
    {
      category: "Hoddies",
      value: "Hoddies",
    },
    {
      category: "T-Shirts",
      value: "T-Shirts",
    },
    {
      category: "Pants",
      value: "Pants",
    },
  ];

  const genders = [
    {
      type: "Male",
      value: "male",
    },
    {
      type: "Female",
      value: "female",
    },
    {
      type: "Unisex",
      value: "unisex",
    },
  ];

  const Positions = [
    {
      position: "Center",
      value: "center",
    },
    {
      position: "Left",
      value: "left",
    },
    {
      position: "Right",
      value: "right",
    },
  ];

  const addNewColor = () => {
    const FilteredList = colorList.filter((cl) => cl === selectedColor);

    console.log(FilteredList);

    if (FilteredList.length > 0) {
      alert("Color Repetido");
    } else {
      setColorList([...colorList, selectedColor]);
    }

    console.log(colorList);
  };

  const RemoveColor = (i) => {
    const filteredColorList = colorList.filter((cl, index) => index !== i);
    setColorList(filteredColorList);
  };

  const ChangeColor = (color) => {
    // console.log(color)
    setSelectedColor(color.target.value);
    // console.log(selectedColor)f
  };



  const addProduct = async (values) => {
    //objeto producto
    const producto = {
      img: product.img,
      productName: values.Product,
      price: values.Price,
      amount: values.Stock,
      category: values.Category,
      gender: values.Gender,
      position: values.Position,
      colors: colorList,
      productSize: values.Size,


    };

    //  console.log(producto)

    setLoading(true);
    try {
      //edicion de objeto en firestore

      const producRef = doc(firestore, "Products", product.id);
      await updateDoc(
        producRef,
        {
          img: producto.img,
          productName: producto.productName,
          price: producto.price,
          amount: producto.amount,
          category: producto.category,
          gender: producto.gender,
          position: producto.position,
          colors: producto.colors,
          productSize: producto.productSize,
        }
      );


      if (file) {
        //se borra imagen anterior 
        const deleteRef = ref(storage, producto.img)
        await deleteObject(deleteRef)

        //se guarda la nueva imagen
        const newRef = ref(
          storage,
          `Productos/${product.id}/${file.name}`
        );

        await uploadBytes(newRef, file);

        const link = await getDownloadURL(newRef);

        // console.log(link);

        await updateDoc(producRef, {
          img: link,
        });
      }
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Product edited and picture uploaded successfully",
        showConfirmButton: false,
        timer: 1500,
      });
      setLoading(false);

      //se cierra la modal

      const btn = document.getElementById("closeButton")
      btn.click()



    } catch (error) {
      //   const errorCode = error.code;
      const errorMessage = error.message;
      console.log(error.message);
      // console.log(errorMessage)
      setLoading(false);

      Swal.fire({
        position: "center",
        icon: "error",
        title: `${errorMessage}`,
        showConfirmButton: false,
        timer: 1500,
      });
    }
  };

  //FORMIK HOOK
  const formik = useFormik({
    initialValues: {
      Code: product.productCode,
      Product: product.productName,
      Price: product.price,
      Stock: product.amount,
      Category: product.category,
      Gender: product.gender,
      Position: product.position,
      Size: product.productSize,
    },
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      console.log(values);
      // alert(JSON.stringify(values, null, 2));

      await addProduct(values);
      actions.setSubmitting(false);
      actions.resetForm({
        values: {
          Code: "",
          Product: "",
          Price: 0,
          Stock: 0,
          Category: "T-Shirts",
          Gender: "unisex",
          Position: "center",
          Size: "",
        },
      });
    },
  });

  const handleChange = (file) => {
    setFile(file);
  };




  return (
    <div className="fixed top-0 left-0 z-50 w-full h-[98vh] overflow-scroll">
      <div className="flex items-center justify-center px-4 pt-4 pb-20 text-center min-height-100vh sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-80" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle md:w-[45vw] w-[95%]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-end w-full">
            <button
              id="closeButton"
              className="relative bg-black text-white px-2 text-[18px] rounded-full text-end"
              onClick={eventClose}
            >
              x
            </button>
          </div>
          {

            Loading === true ?
              <LoadingSpinner />
              :
              <>


                <div className="p-2 bg-white">
                  <div className="flex flex-col mx-2 md:flex-row">
                    <div className="flex flex-col self-center justify-center w-full mx-2">
                      <div className="transition-all ease-out delay-150 bg-white">
                        <div className="flex flex-col md:flex-col">
                          <div className="flex flex-col text-center self-center my-2 border-[3px] rounded-md border-gray">
                            <FileUploader
                              handleChange={handleChange}
                              name="file"
                              types={fileTypes}
                            />
                            <img
                              src={
                                //poner product.img si se sube una nueva reemplaza la anterior
                                file ? URL.createObjectURL(file) : product.img
                              }
                              alt=""
                              className="w-full h-[200px] mx-auto object-contain p-2 m-4"
                            />
                          </div>
                          <div className="flex flex-col self-center my-4 md:w-4/5 md:mx-2">
                            <form onSubmit={formik.handleSubmit}>
                              <div className="grid gap-3 mb-6 md:grid-cols-2">
                                <div className="mt-4">
                                  <label
                                    htmlFor="Code"
                                    className="block text-gray-700"
                                  >
                                    Product Code
                                  </label>
                                  <input
                                    id="Code"
                                    name="Code"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Code}
                                  />
                                  {formik.errors.Code ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Code}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="Product"
                                    className="block text-gray-700"
                                  >
                                    Product
                                  </label>
                                  <input
                                    id="Product"
                                    name="Product"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Product}
                                  />
                                  {formik.errors.Product ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Product}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="Price"
                                    className="block text-gray-700"
                                  >
                                    Price
                                  </label>
                                  <input
                                    id="Price"
                                    name="Price"
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Price}
                                  />
                                  {formik.errors.Price ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Price}
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mt-4">
                                  <label
                                    htmlFor="email"
                                    className="block text-gray-700"
                                  >
                                    Stock
                                  </label>
                                  <input
                                    id="Stock"
                                    name="Stock"
                                    type="number"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Stock}
                                  />
                                  {formik.errors.Stock ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Stock}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-4">
                                  <label
                                    htmlFor="Category"
                                    className="block text-gray-700"
                                  >
                                    Category
                                  </label>
                                  <select
                                    id="Category"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Category}
                                  >
                                    {categorias.map((value, i) => {
                                      return (
                                        <option key={i} value={value.value}>
                                          {value.category}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {formik.errors.Category ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Category}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-4">
                                  <label
                                    htmlFor="Gender"
                                    className="block text-gray-700"
                                  >
                                    Gender
                                  </label>
                                  <select
                                    id="Gender"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Gender}
                                  >
                                    {genders.map((value, i) => {
                                      return (
                                        <option key={i} value={value.value}>
                                          {value.type}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {formik.errors.Gender ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Gender}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="mt-4">
                                  <label
                                    htmlFor="Position"
                                    className="block text-gray-700"
                                  >
                                    Position
                                  </label>
                                  <select
                                    id="Position"
                                    type="text"
                                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
                                    onChange={formik.handleChange}
                                    value={formik.values.Position}
                                  >
                                    {Positions.map((value, i) => {
                                      return (
                                        <option key={i} value={value.value}>
                                          {value.position}
                                        </option>
                                      );
                                    })}
                                  </select>
                                  {formik.errors.Position ? (
                                    <div className="font-bold text-red-600">
                                      {formik.errors.Position}
                                    </div>
                                  ) : null}
                                </div>

                                <div className="flex flex-wrap items-center">
                                  <span className="w-full">Color</span>
                                  <div>
                                    <div className="flex items-center self-center mb-2">
                                      <input
                                        type="color"
                                        id="color"
                                        onChange={ChangeColor}
                                        value={selectedColor}
                                        className="w-[200px] h-[35px]"
                                      />
                                      <span
                                        id="new"
                                        onClick={addNewColor}
                                        className="bg-indigo-600 text-white p-2 rounded-[2px]"
                                      >
                                        <BsPlusLg className="text-[12px]" />
                                      </span>
                                    </div>
                                    <div id="wrapper">
                                      <ul id="listColor" className="flex">
                                        {colorList.map((color, i) => {
                                          return (
                                            <li
                                              key={i}
                                              style={{
                                                //Poner por el color negro
                                                backgroundColor: color,
                                              }}
                                              className="mx-[2px] rounded-[4px]"
                                            >
                                              <div className="buttons">
                                                <span
                                                  className="cursor-pointer remove"
                                                  onClick={() => RemoveColor(i)}
                                                >
                                                  <BiTrash className="text-[30px] text-white p-[3px]" />
                                                </span>
                                              </div>
                                            </li>
                                          );
                                        })}
                                      </ul>
                                    </div>
                                  </div>
                                  {/* Color Picker */}
                                  <div className="flex flex-wrap"></div>
                                </div>
                                <div className="flex items-center self-center">
                                  <span className="mr-3">Size</span>
                                  <div className="flex flex-col">
                                    <input
                                      id="Size"
                                      name="Size"
                                      type="text"
                                      className="bg-gray-50 mx-[2px] border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block p-1 focus:ring-blue-500 w-[100px] text-center my-1"
                                      onChange={formik.handleChange}
                                      value={formik.values.Size}
                                    />
                                    {formik.errors.Size ? (
                                      <div className="font-bold text-red-600">
                                        {formik.errors.Size}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="flex justify-center py-4">
                                <button
                                  type="submit"
                                  className="flex px-4 py-3 mx-5 text-white transition-all transform bg-blue-600 rounded-md shadow-lg outline-none focus:ring-4"
                                  onClick={() => {
                                    //mostrar los valores nuevos del formulario
                                    console.log(formik.values);
                                  }
                                  }
                                >
                                  <BiSave className="w-4 h-6" />
                                  <span className="ml-2">Update</span>
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </>
          }

        </div>
      </div>
    </div>
  );
}

export default EditProduct;
