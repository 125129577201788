import React from 'react'
import { Routes,Route ,Navigate} from 'react-router-dom';
import Login from '../components/Login/Login';


const AuthRoutes = () => {
  return (
    
     <Routes>
       <Route exact path="/login" element={<Login />} />
       <Route path="/" element={<Login />} />
        {/* <Route path="/create-account" exact={true}>
          <CreateAccount />
        </Route>
        <Route path="*" exact={true}>
          <Redirect to="/login" />
        </Route> */}

        <Route
              path="*"
              element={ <Navigate replace to="/"  />}
            />
        </Routes>
     
  )
}

export default AuthRoutes

