import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { CgShapeCircle } from "react-icons/cg";
import { TbListDetails } from "react-icons/tb";
import BaseLayout from "../../Layout/BaseLayout";
import ModalDetail from "../Modal/ModalDetailOrder";
//firestore

import {  useFirestore, useFirestoreCollectionData} from 'reactfire';

import {  collection, orderBy, query } from 'firebase/firestore';

import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';
import { OrderStatusEnums } from '../../utils/utils';

function Tables() {
  const [showModal, setShowModal] = useState(false);
  const [tempdata, setTempdata] = useState([]);



  /// firestore

  const firestore = useFirestore();
  const ordersCollection = collection(firestore, 'Orders');
  const [isAscending] = useState(false);
  const ordersQuery = query(ordersCollection, orderBy('createdAt', isAscending ? 'asc' : 'desc') 
    
  );
  const { status, data } = useFirestoreCollectionData(ordersQuery, {
    idField: 'id',
  });

  // console.log(status);
  console.log(data)

  const getData = (
    // get data from modal
    productName,
    clientName,
    clientEmail,
    clientPhone,
    address,
    orderStatus,
    preview,
    orderQuantity,
    orderTotal,
    orderNote,
    id
  ) => {
    let temData = [
      productName,
      clientName,
      clientEmail,
      clientPhone,
      address,
      orderStatus,
      preview,
      orderQuantity,
      orderTotal,
      orderNote,
      id
    ];
    setTempdata(() => [1, ...temData]);//se agrega el numero uno como valor 0
    return setShowModal(!showModal);
  };


  //Start Datatable Columns
  const columns = [

    {
      name: "Product Name",
      selector: row => row.product_name,
      sortable: true,
      filterable: true,
    },
    {
      name: "Product",
      selector: row => row.product,
      sortable: true,
    },
    {
      name: "Name",
      selector: row => row.name,
      sortable: true,
      filterable: true,
      omit: true,
    },
    {
      name: "Email",
      selector: row => row.email,
      sortable: true,
    },
    {
      name: "Number",
      selector: row => row.number,
      sortable: true,
    },
    {
      name: "Address",
      selector: row => row.address,
      sortable: true,
    },
    {
      name: "Status",
      selector: row => row.status,
      sortable: true,
    },

    {
      name: "Quantitys",
      selector: row => row.orderQuantity,
      sortable: true,
    },
    {
      name: "Total",
      selector: row => row.orderTotal,
      sortable: true,
    },

    {
      name: "Note",
      selector: row => row.orderNote,
      sortable: true,
    },
    {
      name: "Details",
      selector: row => row.details,
      sortable: true,
    },
  ];

  // console.log(data)

  const getItemFromOrderStatus = (OS) => {

    switch (OS) {
      case OrderStatusEnums.Placed:
        return <button className="flex items-center self-center">
          <CgShapeCircle className="text-green-600 text-[25px]" />
          Order {OS}
        </button>

      case OrderStatusEnums.Confirmed:
        return <button className="flex items-center self-center">
          <CgShapeCircle className="text-yellow-600 text-[25px]" />
          Order {OS}
        </button>

      case OrderStatusEnums.Packed:
        return <button className="flex items-center self-center">
          <CgShapeCircle className="text-blue-600 text-[25px]" />
          Order {OS}
        </button>

      case OrderStatusEnums.Shipped:
        return <button className="flex items-center self-center">
          <CgShapeCircle className="text-black text-[25px]" />
          Order {OS}
        </button>



      default:
        return <button className="flex items-center self-center">
          <CgShapeCircle className="text-red-600 text-[25px]" />
          ???????{OS}
        </button>

    }

    // list.orderStatus === "placed" ? (
    //   <button className="flex items-center self-center">
    //     <CgShapeCircle className="text-green-600 text-[25px]" />
    //     Order Placed
    //   </button>
    // ) : list.orderStatus === "confirmed" ? (
    //   <button className="flex items-center self-center">
    //     <CgShapeCircle className="text-yellow-600 text-[25px]" />
    //     Order Confirmed
    //   </button>
    // ) :
    //   list.orderStatus === "packed" ? (
    //     <button className="flex items-center self-center">
    //       <CgShapeCircle className="text-blue-600 text-[25px]" />
    //       Order Packed
    //     </button>
    //   ) :
    //     (
    //       <button className="flex items-center self-center">
    //         <CgShapeCircle className="text-blue-600 text-[25px]" />
    //         Order shipped
    //       </button>
    //     ),
  }

  if (status === 'loading') {
    return (
      <LoadingSpinner label={'Cargando'} />
    )
  }

  return (
    <BaseLayout>
      <h2 className="text-[22px] font-bold text-center my-2">Order List</h2>
      <div className="text-black">
        <DataTable
          columns={columns}
          //Customize Data
          data={data.map((list, id) => ({
            id: id,
            product_name: list.productName,
            product: (
              <img
                src={list.preview}
                alt="img"
                className="w-[40px] h-[40px] object-cover"
              />
            ),
            name: list.clientName,
            email: list.clientEmail,
            number: list.clientPhone,
            address: list.address,
            orderQuantity:list.orderQuantity,
            orderTotal:list.orderTotal,
            status:
              getItemFromOrderStatus(list.orderStatus),
            details: (
              <button
                className="flex items-center self-center text-[#e04f1e]"
                onClick={() =>
                  getData(
                    list.productName,
                    list.clientName,
                    list.clientEmail,
                    list.clientPhone,
                    list.address,
                    list.orderStatus,
                    list.preview,
                    list.orderQuantity,
                    list.orderTotal,
                    list.orderNote,
                    list.id
                  )
                }
              >
                <TbListDetails className="text-[22px] mr-1" />
                More
              </button>
            ),

            orderNote: list.orderNote,
          }))}
          pagination
          isFilterable={true}
        />

        {showModal ? (
          <ModalDetail
            //1//0
            // productName,1
            // clientName,2
            // clientEmail,3
            // clientPhone,4
            // address,5
            // orderStatus,6
            // preview,7
            // orderNote,8
            eventClose={() => {
              setShowModal(!showModal);
            }}
            productName={tempdata[1]}
            //Mostrar detalles de acuerdo al item seleccionado
            orderDetail={tempdata.slice(2, 6).map((item, index) => {
              return (
                <input
                  type="text"
                  key={index}
                  name="Product Name"
                  placeholder="Product Name"
                  value={item}
                  className="w-full px-2 py-1 my-2 mt-2 focus:hidden"
                  autoFocus
                  disabled
                  required
                />
              );
            })}
            imgOrder={tempdata[7]}
            noteOrder={
              <textarea
                className="mx-2 my-10 rounded-md w-full border-[2px] border-gray p-2"
                defaultValue={tempdata[10]}
              ></textarea>
            }
            emailClient={tempdata[2]}
            phoneClient={tempdata[3]}
            orderStatus={tempdata[6]}
            orderQuantity={tempdata[8]}
            orderTotal={tempdata[9]}
            id={tempdata[11]}
          />
        ) : null}
      </div>
    </BaseLayout>

  );
}

export default Tables;
