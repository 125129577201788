import { createRoot } from "react-dom/client";
import App from "./App";
import 'tw-elements';

import { FirebaseAppProvider} from 'reactfire';
const container = document.getElementById("root");
const root = createRoot(container);

const firebaseConfig = {
  apiKey: "AIzaSyB4XRW-SXrqcCi3Tf2qWKVG6V4A-EpYWjI",
  authDomain: "printwarpstudioapp-1870e.firebaseapp.com",
  projectId: "printwarpstudioapp-1870e",
  storageBucket: "printwarpstudioapp-1870e.appspot.com",
  messagingSenderId: "510414085015",
  appId: "1:510414085015:web:3bebd55a1ec926f28202d2",
  measurementId: "G-4BN52WLS78"
};

root.render(
  <FirebaseAppProvider firebaseConfig={firebaseConfig} suspense={false}>
 
    <App />
 

  </FirebaseAppProvider>
);
