import React, { useState } from "react";
import DataTable from "react-data-table-component";
import { BiEditAlt } from "react-icons/bi";
import { BsFillTrashFill } from "react-icons/bs";

//Modal Img Product
import ModalProduct from "../Modal/ModalProduct";

//Modal Edit Product
import ModalEditProduct from "../Modal/EditProduct";

import { useFirestore, useFirestoreCollectionData } from "reactfire";

import { collection, query, where, orderBy } from "firebase/firestore";

import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import DeleteProduct from "../Modal/DeleteProduct";

function AllProducts() {
  const firestore = useFirestore();
  const productsCollection = collection(firestore, "Products");

  const [active, setActive] = useState(true);
  const [isAscending] = useState(false);

  //Seleted Product
  const [selectedProduct, setSelectedProduct] = useState({});

  //Modal Img Product
  const [modalPreview, setModalPreview] = useState(false);
  //Modal Edit Product
  const [modalEdit, setModalEdit] = useState(false);
  //Modal Delete Product
  const [modalDelete, setModalDelete] = useState(false);

  //img Product
  const [productPreview, setProductPreview] = useState([]);

  const productsQuery = query(
    productsCollection,

    orderBy("createdAt", isAscending ? "asc" : "desc"),
    where("active", "==", active)
  );
  const { status, data } = useFirestoreCollectionData(productsQuery, {
    idField: "id",
  });

  const getDataProduct = (
    // get data from modal Product Img
    code,
    preview
  ) => {
    let productPreview = [code, preview];
    setProductPreview(() => [1, ...productPreview]); //se agrega el numero uno como valor 0
    //retornar el estado de los modales
    return setModalPreview(!modalPreview);
  };

  //Start Data Table Columns
  const columns = [
    {
      name: "Code Product",
      selector: (row) => row.code,
      sortable: true,
      filterable: true,
    },
    {
      name: "Preview",
      selector: (row) => row.preview,
      sortable: true,
    },
    {
      name: "Product",
      selector: (row) => row.product,
      sortable: true,
      filterable: true,
    },
    {
      name: "Price",
      selector: (row) => row.price,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      omit: true,
    },
    {
      name: "Category",
      selector: (row) => row.category,
      sortable: true,
    },
    {
      name: "Gender",
      selector: (row) => row.gender,
      sortable: true,
    },
    {
      name: "Position",
      selector: (row) => row.position,
      sortable: true,
    },
    {
      name: "Color",
      selector: (row) => row.color,
      sortable: true,
    },
    {
      name: "Available",
      selector: (row) => row.available,
      sortable: true,
    },
    {
      name: "Actions",
      selector: (row) => row.actions,
      sortable: true,
    },
  ];

  const EstadosProductos = [
    {
      label: "Active",
      value: true,
    },
    {
      label: "Inactive",
      value: false,
    },
  ];

  const getPalete = (colors = []) => {
    if (colors.length > 0) {
      return (
        <div className="flex flex-row gap-1">
          {colors.map((col, index) => (
            <div
              key={index}
              className="w-6 h-6 border border-gray-700 rounded-full"
              style={{ backgroundColor: col }}
            ></div>
          ))}
        </div>
      );
    }
  };

  if (status === "loading") {
    return <LoadingSpinner />;
  }

  return (
    <div className="mb-20">
      <div className="mt-4">
        <label htmlFor="Position" className="block text-gray-700">
          Products Filter
        </label>
        <select
          className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  focus:border-blue-500 block w-full p-2.5 focus:ring-blue-500 "
          onChange={(e) => {
            const val = e.target.value.toLowerCase() === "true" ? true : false;
            setActive(val);
          }}
          value={active}
        >
          {EstadosProductos.map((value, i) => {
            return (
              <option key={i} value={value.value}>
                {value.label}
              </option>
            );
          })}
        </select>
      </div>
      <div className="text-black">
        <DataTable
          //Customize Table
          columns={columns}
          //Customize and Map Data
          data={data.map((list) => ({
            code: list.productCode,
            preview: (
              <img
                src={list.img}
                alt="img"
                className="w-[40px] h-[40px] object-cover"
                onClick={() => getDataProduct(list.img, list.id)}
              />
            ),
            product: list.productName,
            price: `$${list.price}`,
            amount: list.amount,
            category: list.category,
            gender: list.gender,
            position: list.position,
            //mostrar en un div el color
            color: getPalete(list.colors),
            available: list.productSize,
            //Acciones de eliminar y editar el producto
            actions: (
              <div className="flex">
                <button
                  className="flex self-center items-center text-white bg-indigo-600 py-[7px] p-2 mx-1 text-[18px] font-bold rounded-lg"
                  onClick={() => {
                    setModalEdit(!modalEdit);
                    setSelectedProduct(list);
                  }}
                >
                  <BiEditAlt />
                </button>
                <button
                  className="flex justify-center tex items-center text-white bg-red-600 py-[7px] p-2 text-[18px] font-bold rounded-lg"
                  onClick={() => {
                    setSelectedProduct(list);
                    setModalDelete(!modalDelete);
                  }}
                >
                  <BsFillTrashFill />
                </button>
              </div>
            ),
          }))}
          pagination
          isFilterable={true}
        />
      </div>

      {/* Modal for Producto Preview */}
      {modalPreview ? (
        <ModalProduct
          setModal={setModalPreview}
          imgProduct={productPreview[1]}
          eventClose={() => setModalPreview(false)}
        />
      ) : null}

      {/* Modal for Edit Product */}
      {modalEdit ? (
        <ModalEditProduct
          setModal={setModalEdit}
          product={selectedProduct}
          eventClose={() => setModalEdit(false)}
        />
      ) : null}

      {/* Modal for Delete Product */}
      {modalDelete ? (
        <DeleteProduct
          eventClose={() => setModalDelete(false)}
          product={selectedProduct}
        />
      ) : null}
    </div>
  );
}

export default AllProducts;
