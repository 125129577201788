import React from "react";
import { BsFillTelephoneOutboundFill } from "react-icons/bs";
import { MdMarkEmailUnread } from "react-icons/md";
import ProgressBarStatusOrder from "./ProgressBarStatusOrder";

function ModalDetail({
  eventClose,
  productName,
  orderDetail,
  imgOrder,
  noteOrder,
  emailClient,
  phoneClient,
  orderStatus,
  orderQuantity,
  orderTotal,
  id,
}) {
  return (
    <div className="fixed top-0 left-0 z-50 w-full h-[98vh] overflow-scroll">
      <div className="flex items-center justify-center px-4 pt-4 pb-20 text-center min-height-100vh sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-80" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle md:w-[45vw] w-[95%]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-end w-full">
            <button
              className="relative bg-black text-white px-2 text-[18px] rounded-full text-end"
              onClick={eventClose}
            >
              x
            </button>
          </div>
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4">
            <div className="flex flex-col mx-2 md:flex-row">
              <div className="md:w-4/5 flex flex-col text-center self-center mx-4 my-2 border-[3px] rounded-md border-gray">
                <span className="text-[18px] font-bold">{productName}</span>

                <img src={imgOrder} alt="Product" />
              </div>
              <div className="flex flex-col self-center mx-2 md:w-4/5">
                <div className="px-2">{orderDetail}</div>
                <div className="">
                  <ProgressBarStatusOrder orderStatus={orderStatus} id={id} />
                </div>
              </div>
              {/* Progress bar status order */}
            </div>
            <div>{noteOrder}</div>
            <div>{orderQuantity}</div>
            <div>{orderTotal}</div>
            <div className="flex">
              <a href={`tel:+1${phoneClient}`}>
                <button
                  type="button"
                  className="flex px-3 py-3 mx-5 text-white transition transform bg-blue-600 rounded-md shadow-lg outline-none focus:ring-4"
                >
                  <BsFillTelephoneOutboundFill className="w-4 h-6" />
                  <span className="ml-2">Call Us</span>
                </button>
              </a>
              <a href={`mailto:${emailClient}`}>
                <button
                  type="button"
                  className="flex px-4 py-3 text-white transition-all transform bg-blue-600 rounded-md shadow-lg outline-none md:mx-5 focus:ring-4"
                >
                  <MdMarkEmailUnread className="w-4 h-6" />
                  <span className="ml-2">Send Email</span>
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDetail;
