import React from "react";
import { BrowserRouter as Router, Route, Routes, } from "react-router-dom";

import Users from "../components/Users/Users";
import Order from "../components/Order/Order";
import Setting from "../components/Setting/Setting";
import ImgEditor from "../components/imgEditor/ImgEditor";
import Product from "../components/Product/Product";
import AuthWrapper from './AuthWrapper';
import AuthRoutes from "./AuthRoutes";
import Logout from "../components/Logout/Logout";
import Help from "../components/Help/Help";

const RoutesApp = () => {
  return (
    <>

      <Router>
      <AuthWrapper fallback={<AuthRoutes />}>
        <Routes>
          <Route exact path="/home" element={<Order />} />
          <Route exact path="/products" element={<Product />} />
          <Route exact path="/users" element={<Users />} />
          <Route exact path="/settings" element={<Setting />} />
          <Route exact path="/img" element={<ImgEditor />} />
          <Route exact path="/logout" element={<Logout />} />
          <Route exact path="/help" element={<Help />} />
          <Route path="/" element={<Order />} />
        </Routes>
      </AuthWrapper>
      </Router>
     
    </>
  );
};

export default RoutesApp;
