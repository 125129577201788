import { AiOutlineShoppingCart } from "react-icons/ai";
import { BiUserCircle,BiLogOutCircle } from "react-icons/bi";
import { MdOutlineDashboard } from "react-icons/md";
import { RiSettings4Line } from "react-icons/ri";
import {FiHelpCircle} from "react-icons/fi";

const Menu = [
  { name: "Order", link: "/", icon: MdOutlineDashboard },
  { name: "Products", link: "/products", icon: AiOutlineShoppingCart },
  { name: "Users", link: "/users", icon: BiUserCircle },
  { name: "Setting", link: "/settings", icon: RiSettings4Line, margin: true },
  { name: "Logout", link: "/logout", icon: BiLogOutCircle, margin: true },
  { name: "Help", link: "/help", icon: FiHelpCircle, margin: true },
];

export default Menu;