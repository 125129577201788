import { useState } from "react";
import { motion } from "framer-motion";
import { Resizable } from "re-resizable";
import { BsArrowsMove } from "react-icons/bs";
import BaseLayout from "../../Layout/BaseLayout"

function ImgEditor() {
  //Estado para el tamaño de la imagen
  const [imgUpload, setimgUpload] = useState(null);

  //Estado del control resize de la imagen
  const [showControls, setShowControls] = useState(false);

  //Estado para subir  la imagen
  const handleImgUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
      setimgUpload(reader.result);
    };
  };

  return (
    <BaseLayout>
      <h1>Upload Imagen</h1>
      {/* Subimos el logo */}
      <input type="file" onChange={handleImgUpload} />

      {/* Background con la imagen de la camisa */}
      <div
        className="w-[300px] relative h-[300px] bg-cover flex justify-center items-center"
        style={{
          backgroundImage: `url("https://firebasestorage.googleapis.com/v0/b/stockredesdirectorios.appspot.com/o/App-Imprenta%2Fcamiseta-V.png?alt=media&token=6197dcbb-8cbd-4ef3-b430-e510476e794e")`,
        }}
      >
        <div className="mb-10 w-[100px] h-[100px] relative border-[1px] border-black">
          <motion.div
            className="bg-cover flex justify-center items-center w-full h-full relative p-2"
            drag
            dragConstraints={{ left: 0, top: 0, right: 0, bottom: 0 }}
          >
            {/* Contenedor que nos permitira cambiar de tamño el logo */}
            <Resizable
              defaultSize={{
                width: "70px",
                height: "70px",
              }}
              minWidth="20px"
              minHeight="20px"
              maxWidth="100px"
              maxHeight="100px"
            >
              {/* Le pasamos la imagen que subimos */}
              <img
                src={imgUpload ? imgUpload : "https://firebasestorage.googleapis.com/v0/b/searchapp-25415.appspot.com/o/61ac41514e4e0f00d6909795_Your%20Logo%20Herw2.png?alt=media&token=d1137d67-2bc9-49ce-a3dd-fd9b8405eae8"}
                alt="Img"
                className="w-full h-full object-contain"
                onClick={() => setShowControls(!showControls)}
              />
              {showControls ? (
                <BsArrowsMove className="absolute top-[-8px] right-[-8px] bg-white p-[1px] text-[16px] rounded-full"></BsArrowsMove>
              ) : null}
            </Resizable>
          </motion.div>
        </div>
      </div>
    </BaseLayout>
  );
}

export default ImgEditor;
