import { getAuth } from "firebase/auth";
import { getStorage} from "firebase/storage";
import { initializeFirestore,  enableIndexedDbPersistence } from 'firebase/firestore';
import Routes from "./Router/Routes";

import "./App.css";
import LoadingSpinner from './components/LoadingSpinner/LoadingSpinner';
import { FirestoreProvider,AuthProvider ,useFirebaseApp, useInitFirestore ,StorageProvider} from 'reactfire';

function App() {

  const app = useFirebaseApp();
 
  const auth= getAuth(app);
  const {status, data: firestoreInstance} = useInitFirestore(async (firebaseApp) => {
    const db = initializeFirestore(firebaseApp, {});
    await enableIndexedDbPersistence(db);
    return db;
  });

  console.log(auth.currentUser);

  if (status ==='loading') {

    return <LoadingSpinner/>
    
  }
  return (
    <AuthProvider sdk={auth}>
       <StorageProvider sdk={getStorage(app)}>
        <FirestoreProvider sdk={firestoreInstance}>
           <Routes></Routes>
        </FirestoreProvider>
        </StorageProvider>


    </AuthProvider>
  );
}

export default App;
