import React from "react";
import { BiLogIn } from "react-icons/bi";
import { useAuth } from "reactfire";
import BaseLayout from "../../Layout/BaseLayout";

const Logout = () => {
  const auth = useAuth();
  return (
    <BaseLayout>
      <div className="flex flex-col items-center justify-center h-screen">
        <button
          onClick={() => signOut(auth)}
          className="flex self-center items-center text-white bg-[#e04f1e] py-[7px] p-2 mx-1 text-[18px] font-bold rounded-lg"
        >
          <BiLogIn /> Sign Out
        </button>
      </div>
    </BaseLayout>
  );
};
const signOut = (auth) => auth.signOut().then(() => console.log("signed out"));
export default Logout;
