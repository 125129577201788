import React, { useState } from "react";
import { useFormik } from "formik";
import { signInWithEmailAndPassword, getAuth } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import LOGO from "../../img/log.png"

function Login() {
  let navigate = useNavigate();
  const auth = getAuth();
  const [Loading, setLoading] = useState(false);

  const doSignIn = async ({ email, password }) => {
    setLoading(true);
    try {
      const respAuth = await signInWithEmailAndPassword(auth, email, password);

      if (respAuth.user) {
        const user = respAuth.user;
        console.log(user);
        navigate(`/`);
        setLoading(false);
      }
    } catch (error) {
      const errorCode = error.code;
      const errorMessage = error.message;
      console.log(errorCode);
      console.log(errorMessage);
      setLoading(false);
      alert(errorMessage);
    }
  };

  //VALIDACIONES PARA FORM
  const validate = (values) => {
    const errors = {};
    // console.log(values)

    if (!values.email) {
      errors.email = "Email Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email address";
    }
    if (!values.password) {
      errors.service = "Password Required";
    }

    return errors;
  };
  //FORMIK HOOK
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validate,
    enableReinitialize: true,
    onSubmit: async (values, actions) => {
      // alert(JSON.stringify(values, null, 2));
      const authBody = {
        email: values.email,
        password: values.password,
      };

      await doSignIn(authBody);
      actions.setSubmitting(false);
      actions.resetForm({
        values: {
          email: "",
          password: "",
        },
      });
    },
  });

  if (Loading) {
    return <LoadingSpinner />;
  }
  return (
    <section className="flex flex-col items-center h-screen md:flex-row">
      <div className="flex items-center justify-center w-full h-screen px-6 bg-white md:max-w-md lg:max-w-full md:mx-auto md:w-1/2 xl:w-1/3 lg:px-16 xl:px-12">
        <div className="w-full h-100 flex flex-col justify-center">
          <img
            src={LOGO}
            alt="Logo"
            className="w-[60%] md:mx-0 mx-auto"
          />
          <h1 className="mt-6 text-xl font-bold leading-tight md:text-2xl">
            Log In To Your Account
          </h1>
          <form onSubmit={formik.handleSubmit}>
            <div className="mt-4">
              <label htmlFor="email" className="block text-gray-700">
                Email
              </label>
              <input
                id="email"
                name="email"
                type="email"
                className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-blue-500 focus:bg-white focus:outline-none"
                onChange={formik.handleChange}
                value={formik.values.email}
              />
              {formik.errors.email ? (
                <div className="font-bold text-red-600">
                  {formik.errors.email}
                </div>
              ) : null}
            </div>
            <div className="mt-4">
              <label htmlFor="password" className="block text-gray-700">
                Password
              </label>
              <input
                id="password"
                name="password"
                type="password"
                className="w-full px-4 py-3 mt-2 bg-gray-200 border rounded-lg focus:border-blue-500 focus:bg-white focus:outline-none"
                onChange={formik.handleChange}
                value={formik.values.password}
              />
              {formik.errors.password ? (
                <div className="font-bold text-red-600">
                  {formik.errors.password}
                </div>
              ) : null}
            </div>

            <button
              type="submit"
              className="block w-full px-4 py-3 mt-6 font-semibold text-white bg-[#E74E1C] rounded-lg hover:bg-[#F08420] focus:bg-indigo-400"
            >
              Submit
            </button>
          </form>
        </div>
      </div>
      <div className="hidden w-full h-screen lg:block md:w-1/2 xl:w-2/3">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/searchapp-25415.appspot.com/o/BgSVG.svg?alt=media&token=2a2c23f3-a643-4564-8643-76bd0ec111a0"
          alt="Background Login"
          className="object-cover w-full h-full bg-center-left"
        />
      </div>
    </section>
  );
}

export default Login;
