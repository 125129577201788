import React from "react";
import { BsArrowRight } from "react-icons/bs";
import BaseLayout from "../../Layout/BaseLayout";

function Help() {
  const modules = [
    {
      name: "1. Order Module",
      text: [
        "In this section you will receive order information from the app.",
      ],
    },
    {
        name: "2. Products",
        text: [
            "List of added products, which will be displayed on the platform in real time with the details and design of each one.",
            "ADD PRODUCT, button that will display a form to add a new product with all the corresponding details.",
            "Please note that the images of the new product must have a recommended resolution of 700px by 700px."
          ],
      },
      {
        name: "3. Users",
        text: [
            "This module will show the users registered on the platform, with the option to delete specific users or spam.",
          ],
      },
  ];

  return (
    <BaseLayout>
      <div>
        <h1 className="capitalize text-[24px] font-bold">
          To get started with the administration, you should consider the
          following:
        </h1>
        {modules.map((module, i) => {
          return (
            <div key={i}>
              <h2 className="capitalize text-[22px] text-[#E04F1E] font-bold py-4">
                {module.name}
              </h2>
              <ul>{module.text.map((text, i) =>{
                return(
                    <li key={i} className="py-2 flex self-center items-center">
                      <BsArrowRight className="mr-2"/>  {text}
                    </li>
                )
              })}</ul>
            </div>
          );
        })}
      </div>
    </BaseLayout>
  );
}

export default Help;
