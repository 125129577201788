import React, { useState } from "react";
import { BsCheck } from "react-icons/bs";
import { VscCircleFilled } from "react-icons/vsc";
import { updateDoc, doc } from "firebase/firestore";
import { useFirestore } from "reactfire";
import Swal from "sweetalert2";

function ProgressBarStatusOrder({ orderStatus, id }) {
  const firestore = useFirestore();
  const ref = doc(firestore, "Orders", id);

  console.log(orderStatus, id);
  //lista generica estados posibles de las ordenes definido por el status actual
  const states = [
    {
      id: 1,
      name: "Placed",
      status:
        orderStatus === "Placed" ||
        orderStatus === "Confirmed" ||
        orderStatus === "Packed" ||
        orderStatus === "Shipped"
          ? "done"
          : "pending",
    },
    {
      id: 2,
      name: "Confirmed",
      status:
        orderStatus === "Confirmed" ||
        orderStatus === "Packed" ||
        orderStatus === "Shipped"
          ? "done"
          : "pending",
    },
    {
      id: 3,
      name: "Packed",
      status:
        orderStatus === "Packed" || orderStatus === "Shipped"
          ? "done"
          : "pending",
    },
    {
      id: 4,
      name: "Shipped",
      status: orderStatus === "Shipped" ? "done" : "pending",
    },
  ];

  //
  const [orderStates, setorderStates] = useState(states);

  const cambiarStatus = async (item) => {
    // console.log(item)

    const cantidadStatusMenores = orderStates.filter((v) => v.id < item.id);
    //  console.log(cantidadStatusMenores)
    const cantidadStatusPending = cantidadStatusMenores.filter(
      (v) => v.status === "pending"
    );
    //  console.log(cantidadStatusPending)

    if (cantidadStatusPending.length > 0) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "No puedes cambiar el estado de la orden hasta que no se complete el estado anterior",
      });

      return;
    }

    try {
      await updateDoc(ref, {
        orderStatus: item.name,
      });

      //Confirmar antes de cambiar el estado
      Swal.fire({
        title: "¿Estas seguro?",
        text: "No podrás revertir este cambio!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, cambiar",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Cambiado!", "El estado de la orden ha sido cambiado.", "success");
        }
      });


      const newState = states.map((obj) => {
        // 👇️ if id equals 2, update country property
        if (obj.id === item.id) {
          return { ...obj, status: "done" };
        }

        // 👇️ otherwise return object as is
        return obj;
      });

      setorderStates(newState);
    } catch (error) {
      alert(`Error updating`);
    }
  };
  return (
    <>
      <div className="flex items-center justify-between h-1 my-6 bg-gray-200">
        {orderStates.map((item, index) => (
          <div
            className="flex items-center justify-center"
            key={index}
            onClick={() => cambiarStatus(item)}
          >
            {item.status === "done" ? (
              <div className="flex items-center justify-center w-6 h-6 bg-green-700 rounded-full shadow">
                <BsCheck className="text-white" />
              </div>
            ) : (
              <div className="relative flex items-center justify-center w-6 h-6 -mr-3 bg-white hover:bg-green-600 rounded-full shadow">
                <VscCircleFilled className="text-[20px] text-green-700 hover:text-white rounded-full" />
              </div>
            )}
            <div
              className={`h-1 md:w-10 w-8 ${
                item.status === "done" ? "bg-green-700" : "bg-gray-500"
              }`}
            ></div>

            {item.id !== orderStates.length && (
              <div
                className={`h-1 md:w-10 w-8 ${
                  item.status === "done" ? "bg-green-700 " : "bg-gray-500"
                }`}
              ></div>
            )}
            <p className="text-black-700 text-xs font-bold mt-16 text-center absolute ml-[-50px] mx-auto">
              {item.name}
            </p>
          </div>
        ))}
      </div>
    </>
  );
}

export default ProgressBarStatusOrder;
