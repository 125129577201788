import React from 'react'

 const Loading = () => {
  return (
    <div className="w-full h-[100vh] bg-white flex justify-center items-center">
    <div className="loader"></div>
     </div>
  )
}

export default Loading;
