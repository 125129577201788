import React from "react";

function ModalProduct({ eventClose, imgProduct }) {
  return (
    <div className="fixed top-0 left-0 z-50 w-full">
      <div className="flex items-center justify-center px-4 pt-4 pb-20 text-center min-height-100vh sm:block sm:p-0">
        <div className="fixed inset-0 transition-opacity">
          <div className="absolute inset-0 bg-gray-900 opacity-80" />
        </div>
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen">
          &#8203;
        </span>
        <div
          className="inline-block align-center bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all align-middle md:w-[30vw] w-[95%]"
          role="dialog"
          aria-modal="true"
          aria-labelledby="modal-headline"
        >
          <div className="flex justify-end w-full">
            <button
              className="relative bg-black text-white px-2 text-[18px] rounded-full text-end"
              onClick={eventClose}
            >
              x
            </button>
          </div>
          <div className="px-4 pt-5 pb-4 bg-white sm:p-6 sm:pb-4 flex justify-center">
            <img src={imgProduct} alt="Product" className="w-[350px] h-[350px] object-contain" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalProduct;
