import React from 'react'
import {
  useSigninCheck,
} from "reactfire";
import Loading from '../components/shared/loading';
const AuthWrapper = ({
  children,
  fallback,
}) => {
  const { status, data: signInCheckResult } = useSigninCheck();
  console.log(signInCheckResult);

  if (!children) {
    throw new Error("Children must be provided");
  }
  if (status === "loading") {
    return <Loading />;
  } else if (signInCheckResult.signedIn === true) {
    return children;
  }

  return fallback;
};

export default AuthWrapper

